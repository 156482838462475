import React, { useState, useMemo, useRef } from 'react';
import { Form, Table, Row, Button, Input, Pagination, PaginationItem, PaginationLink, FormGroup, Col, Label } from 'reactstrap';
import { useTranslate } from 'react-translate';
import { useSortBy, useTable, usePagination } from 'react-table';
import moment from "moment";
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import LaboratoryService from "../../services/LaboratoryService";
import LoadingLocal from 'plugins/TS-lib-utils-public/components/Loading/LoadingLocal';
import nativeSender from 'helpers/nativeSender';
import { actionGetLabStudies } from 'store/actions/laboratoryActions';
import { actionSetLoadingStudy, actionSetSearchQuery } from 'store/actions/laboratoryActions';
import { actionInvitePatient } from 'store/actions/patientsActions';

const StudiesList = () => {
    const t = useTranslate('studies-list');
    const dispatch = useDispatch();
    const thl7 = useTranslate('hl7-status');
    const { handleSubmit, register } = useForm();
    const practiceId = useSelector(state => state.practicesReducer.practice._id);
    const { doctorInfo } = useSelector(state=>state.userDataReducer);
    const user = useSelector(state => state.userReducer);
    const laboratoryList = useSelector(state => state.laboratoryReducer.studies);
    const refreshTable = useRef(false);
    const pageLimit = useSelector(state => state.laboratoryReducer.lastPage);
    const loadingStudyList = useSelector(state => state.laboratoryReducer.loadingStudyList);
    const [sortDate, setSortDate] = useState(-1);
    const querySearch = useSelector(state => state.laboratoryReducer.querySearch);
    const [dateFrom, setDateFrom] = useState("");
    const [dateTo, setDateTo] = useState("");
    const [showDatePick, setShowDatePick] = useState(false);
    const history = useHistory();
    const { nativeWebView } = useSelector(state => state.commonReducer);

    const invitePatient = (patientId, reportId) => {
        dispatch(actionInvitePatient(patientId, doctorInfo.doctor_pifs_id, reportId));
    }

    const onSubmit = (data) => {
        dispatch(actionSetSearchQuery(data.query));
        gotoPage(0);
    }

    function base64ToArrayBuffer(base64) {
        var binaryString = window.atob(base64);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    }

    // Function will execute on click of button
    const convertPDFToUrl = async (reportId, doctorId, patientId) => {
        try {
            if (nativeWebView) {
                return nativeSender.send('DOWNLOAD_REPORT', { reportId: reportId, doctorId: doctorId, patientId: patientId });
            }
            const data = await LaboratoryService.getStudyReportFile(reportId, doctorId, patientId);
            const reportFile = await data.data;

            // using Java Script method to get PDF file
            var bytes = base64ToArrayBuffer(reportFile);
            var blob = new Blob([bytes], { type: "application/pdf" });
            // Creating new object of PDF file
            const fileURL = window.URL.createObjectURL(blob);
            const fileName = `${reportId}.pdf`;


            //const linkSource = `data:application/pdf;base64,${reportFile}`;
            const downloadLink = document.createElement("a");
            downloadLink.href = fileURL;
            downloadLink.download = fileName;
            downloadLink.click();
            //return fileURL
        } catch (error) {
            console.log(error);
        }

    }
    //componente para poder mostrar el estado de carga del boton mientras se espera el pdf del llamado a la api
    const LoadingButton = ({ reportId, doctorId, patientId }) => {
        const [isLoading, setIsLoading] = useState(false);

        useEffect(() => {
            if (isLoading) {
                convertPDFToUrl(reportId, doctorId, patientId).then(() => {
                    setIsLoading(false);
                })
            }
        }, [isLoading]);

        const handleClick = () => setIsLoading(true);
        return (
            <Button
                size="sm"
                color="info"
                onClick={!isLoading ? handleClick : null}
                target="_blank"
                rel="noopener noreferrer"
                disabled={isLoading}
            //descomentar la siguiente linea si lo que se desea es que se descargue directamente
            //download={reportId + ".pdf"}
            >
                {isLoading ? "..." : <i className="icon icon-attach ml-2"></i>}

            </Button>
        )
    }

    // const handleInfoClick = (index) => {
    //     const updatedState = data[index];

    //     if (updatedState.other) {
    //         delete updatedState.other;
    //         setState((pre) => {
    //             return pre + 1;
    //         });
    //     } else {
    //         updatedState.other = {
    //             description: "Hello there" //or data from api
    //         };
    //         setState((pre) => {
    //             return pre + 1;
    //         });
    //     }
    // };

    const data = useMemo(
        () => laboratoryList && laboratoryList != "loading" ? laboratoryList.map((p, i) => {
            const reportData = {
                registerid: p.reportId,
                eingangsdatum: moment(p.created_at, "YYYYMMDDhhmmss").format(user.dateFormat),
                patient: `${p.patient.firstName} ${p.patient.lastName} * ${moment(p.patient.birthday, "YYYYMMDD").format(user.dateFormat)}`,
                review: p.review?.readed_at ? t("status-readed") : !p.review?.sent_at ? t("status-open") : t("status-sent"),
                additionalInfo: {
                    "Practice": p.practice,
                    "Study ID": p.reportId,
                    "Control Number": p.controlNumber,
                    "Order Number": p.orderNumber,
                },
                status: `${thl7(p.status)}`,
                berichtsdatum: moment(p.updated_at, "YYYYMMDDhhmmss").format(user.dateFormat),
                telepraxenUser:
                    <React.Fragment key={`patient-istp-${p.patient.isTelepraxen}-${i}`}>
                        { p.patient.isTelepraxen ? (
                            <Button color="info" active={false} disabled>
                                TP
                            </Button> 
                        ) : (
                            <Button color="primary" onClick={() => {
                                invitePatient(p.patient.pifs_id, p.reportId);
                            }}>
                                Einladen <i className="icon icon-send ml-1"></i>
                            </Button> 
                        )
                        }
                    </React.Fragment>
                ,
                c5: p?.readed ? "true" : "",
                c6: 
                    <Button
                        key={`report-link-${p.reportId}-${p.patient.pifs_id}`}
                        color="info"
                        onClick={() => {
                            dispatch(actionSetLoadingStudy(true));
                            history.push(`lab-studies-detail/${p.reportId}/${p.patient.pifs_id}`);
                        }}
                    >
                        {t('details')}
                    </Button>,
                c7: p.reportFileExists && <LoadingButton reportId={p.reportId} doctorId={p.doctor.pifs_id} patientId={p.patient.pifs_id} />,

                //TODO - REsolve toggle hide/show additional info
                //info: <Button key={`ButtonToggler${p.reportId}-${p.patient.pifs_id}`} id={`ButtonToggler${p.reportId}`} color="info" >&#9432;</Button>
            };

            return { ...reportData };
        }) : [],
        //eslint-disable-next-line
        [laboratoryList, t]
    );


    const columns = useMemo(
        () => [
            {
                accessor: "registerid",
                // defaultCanSort: false
            },
            {
                accessor: "additionalInfo",
                // defaultCanSort: false
            },
            {
                Header: `${t('report-date')}`,
                accessor: "berichtsdatum",
                // defaultCanSort: false,
            },
            {
                accessor: "telepraxenUser",
                // defaultCanSort: false,
                // disableSortBy: true
            },
            {
                Header: `${t('patient')}`,
                accessor: "patient",
                // defaultCanSort: false,
                // disableSortBy: true
            },
            {
                Header: `Kommentar`,
                accessor: "review",
                // defaultCanSort: false,
                // disableSortBy: true
            },
            {
                Header: `${t('status')}`,
                accessor: "status",
                // defaultCanSort: false,
                // disableSortBy: true
            },
            {
                Header: `${t('date-of-receipt')}`,
                accessor: "eingangsdatum",
                // defaultCanSort: false,
                // disableSortBy: true
            },
            {
                Header: `${t('actions')}`,
                accessor: "c6",
                // defaultCanSort: false,
                // disableSortBy: true
            },
            {
                accessor: "c7",
                // defaultCanSort: false,
                // disableSortBy: true
            },
            // TODO - REsolve toggle hide/show additional info
            // {
            //     accessor: "info",
                // defaultCanSort: false,
                // disableSortBy: true
            // }
        ],
        //eslint-disable-next-line
        [t]
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize }
    } = useTable({
        columns,
        data,
        disableSortRemove: true,
        enableSortingRemoval: true,
        defaultCanSort: true,
        initialState: {
            pageSize: 20,
            hiddenColumns: ["registerId", "additionalInfo"],
        },
        manualPagination: true,
        pageCount: pageLimit + 1,
        autoResetPage: !refreshTable.current
    },
        useSortBy,
        usePagination);

    useEffect(() => {
        refreshTable.current = true;
        document.getElementById("full-page").scroll(0,0);
        dispatch(actionGetLabStudies({
            practiceId, 
            pageIndex, 
            pageSize, 
            sortDate, 
            querySearch, 
            dateFrom,
            dateTo
        }));
    }, [pageIndex, pageSize, sortDate, querySearch]);


    useEffect(() => {
        refreshTable.current = false;
    }, []);

    useEffect(() => {
        gotoPage(0);
        document.getElementById("full-page").scroll(0,0);
        dispatch(actionGetLabStudies({
            practiceId, 
            pageIndex, 
            pageSize, 
            sortDate: -1, 
            querySearch,
            dateFrom,
            dateTo
        }));
    }, [dateFrom, dateTo]);

    return (
        <>

            <Form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col xs={4} sm={3}>
                        <FormGroup
                        className="mt-2">
                            <Input 
                            className="form-control"
                                type="text"
                                innerRef={register}
                                name="query"
                                id="query"
                                placeholder={t('search-patient')}
                                defaultValue={querySearch}
                            />

                            {querySearch.length ? (
                                <button 
                                className="msg_send_btn" 
                                type="button" 
                                onClick={() => {
                                    document.getElementById("query").value = "";
                                        dispatch(actionSetSearchQuery(""))
                                    }}>
                                    <span className="icon-trash"></span>
                              </button>
                            ) : (<></>)}
                        </FormGroup>
                    </Col>

                    <Col xs={1}>
                        <Button
                            size="md"
                            color="info"
                            type="submit"
                            name="search-study"
                        >
                            {t("search-button")}
                        </Button>
                    </Col>

                    <Col className="ml-auto mr-3" xs={4} lg={2}>
                        <FormGroup className="mt-2">
                            <Input type="select" name="filter-date-study" onChange={(e) => {
                                setShowDatePick(isNaN(new Date(e.target.value)));
                                let date = new Date(e.target.value);
                                if (!isNaN(date)) {
                                    if (date.getFullYear() < 1900) {
                                        setDateFrom(null);
                                        setDateTo(null);
                                    } else {
                                        setDateFrom(e.target.value);
                                    }
                                    setShowDatePick(false);
                                }
                            }}>
                                <option value={"1800-01-01"} selected>
                                    {t("datepick-show-all")}
                                </option>
                                <option value={moment(Date.now() - 7 * 24 * 60 * 60 * 1000).format("YYYY-MM-DD")}>
                                    {t("datepick-last7")}
                                </option>
                                <option value={moment(Date.now() - 30 * 24 * 60 * 60 * 1000).format("YYYY-MM-DD")}>
                                    {t("datepick-last-month")}
                                </option>
                                <option>
                                    {t("datepick-personalized")}
                                </option>
                            </Input>
                         </FormGroup>
                    </Col>

                    
                </Row>

                {showDatePick && (
                    <Row>
                        <Col>
                            <Label>{t('datepick-from')}</Label>
                            <FormGroup>
                                <Input
                                    name="from"
                                    id="from"
                                    type="date"
                                    innerRef={register}
                                    onChange={(e) => {
                                        setDateFrom(e.target.value);
                                    }}
                                />
                            </FormGroup>
                        </Col>

                        <Col>
                            <Label>{t('datepick-to')}</Label>
                            <FormGroup>
                                <Input
                                    name="to"
                                    id="to"
                                    type="date"
                                    innerRef={register}
                                    onChange={(e) => {
                                        setDateTo(e.target.value);
                                    }}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                )}
            </Form>
            

            {loadingStudyList ? <LoadingLocal/> : (
                <div className="table-responsive">
                    <Table {...getTableProps()}>
                        <thead>
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <th
                                            className={`session-table-header ${column.id === "c4" ? "sortable-header" : ""} ${column.id === "registerid" ? "d-none" : ""}`}
                                            {...column.getHeaderProps(column.getSortByToggleProps())}
                                            onClick={column.id === "berichtsdatum" ? e => {
                                                setSortDate(!sortDate ? 1 : sortDate === 1 ? -1 : 0);
                                            } : undefined}
                                        >
                                            {column.render('Header')}

                                            {column.id === "berichtsdatum" && (
                                                <span>
                                                    <b className={`${sortDate != 0 ? sortDate === -1 ? "caret-desc" : "caret-asc" : "caret-default"}`}></b>
                                                </span>
                                            )}

                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {laboratoryList === "loading" ? (<tr><td><LoadingLocal /></td></tr>)
                                : (
                                    page.map((row, index) => {
                                        prepareRow(row)
                                        return (
                                            <React.Fragment key={`tbody-${index}`}>
                                                <tr {...row.getRowProps((state, rowInfo) => {
                                                    if (rowInfo && rowInfo.row) {
                                                        return {
                                                            style: {
                                                                fontWeight: !rowInfo.row.original.c5.length ? 'bold' : 'normal',
                                                            }
                                                        }
                                                    } else return {}
                                                })}>
                                                    {row.cells.map((cell, ci) => {
                                                        return (
                                                            <td
                                                                {...cell.getCellProps({ key: `tr_${index}_${ci}` })}
                                                                className={`${cell.column.id === "c1" ? "d-flex" : ""} ${cell.column.id === "registerid" ? "d-none" : ""}`}
                                                            >
                                                                {cell.column.id === "c1" && <div className={`dot-${cell.value === "Unavailable" ? "un" : ''}available`}></div>}
                                                                {cell.render('Cell')}
                                                            </td>
                                                        )
                                                    })}
                                                </tr>

                                                <tr {...row.getRowProps({ key: `row_${index}_2` })}>
                                                    {/* <pre> */}
                                                    {
                                                        row.cells.map((cell, index) => {
                                                            {/* { console.log(cell.column.id) } */ }

                                                            {/* {
                                                                    if (cell.column.id == "registerid") {
                                                                        return (
                                                                            <pre>{cell.column.id}</pre>
                                                                        )
                                                                    } else {
                                                                        return (null)
                                                                    }
                                                                } */}
                                                            if (cell.column.id === "registerid") {

                                                                return (

                                                                    <td {...cell.getCellProps({ key: `cell_${index}_2` })} colSpan={9} style={{ borderTop: "none" }}>
                                                                        {/* 
                                                                        
                                                                        TODO - Resolve toggle hide/show aditional info
                                                                        
                                                                        <UncontrolledCollapse
                                                                            toggler={`#ButtonToggler${cell.value}`}>
                                                                            <>
                                                                                {Object.keys(cell.row.allCells[1].value).map((v,i) => {
                                                                                    return (
                                                                                        <p key={`p-${v}-${i}`}>{`${v}: ${cell.row.allCells[1].value[v]}`}</p>
                                                                                    )
                                                                                })}
                                                                            </>
                                                                        </UncontrolledCollapse>
                                                                            */}
                                                                    </td>
                                                                )
                                                            }
                                                        })
                                                    }
                                                    {/* </pre> */}
                                                </tr>
                                            </React.Fragment>
                                        )
                                    })
                                )}
                        </tbody>
                    </Table>
                </div>
            )}
            

            <Row className="text-center justify-content-center mt-3">
                <nav aria-label="Page navigation example">
                    <Pagination
                        className="pagination justify-content-end"
                        listclassname="justify-content-end"
                    >
                        <PaginationItem disabled={!canPreviousPage}>
                            <PaginationLink
                                href="#previous-page"
                                onClick={e => {
                                    e.preventDefault()
                                    previousPage()
                                }}
                                tabIndex="-1"
                                className="bg-info text-white"
                            >
                                <i className='fa fa-angle-double-left'></i>
                            </PaginationLink>
                        </PaginationItem>
                        <PaginationItem disabled={pageIndex < 2}>
                            <PaginationLink href="#minus-two" onClick={e => {
                                e.preventDefault();
                                gotoPage(pageIndex - 2);
                            }}>
                                {canPreviousPage && pageIndex > 1 ? pageIndex - 1 : " "}
                            </PaginationLink>
                        </PaginationItem>
                        <PaginationItem disabled={pageIndex === 0}>
                            <PaginationLink href="#minus-one"
                                onClick={e => {
                                    e.preventDefault();
                                    previousPage()
                                }}>
                                {!canPreviousPage ? " " : pageIndex}
                            </PaginationLink>
                        </PaginationItem>
                        <PaginationItem active>
                            <PaginationLink href="#actual-page" onClick={e => { e.preventDefault() }} className="bg-info">
                                {pageIndex + 1}
                            </PaginationLink>
                        </PaginationItem>
                        <PaginationItem disabled={!canNextPage}>
                            <PaginationLink href="#plus-one" onClick={e => {
                                e.preventDefault();
                                nextPage();
                            }}>
                                {canNextPage ? pageIndex + 2 : " "}
                            </PaginationLink>
                        </PaginationItem>
                        <PaginationItem disabled={!(pageCount - pageIndex > 2)}>
                            <PaginationLink href="#plus-two" onClick={e => {
                                e.preventDefault();
                                gotoPage(pageIndex + 2);
                            }}>
                                {(pageCount - pageIndex > 2) ? pageIndex + 3 : " "}
                            </PaginationLink>
                        </PaginationItem>
                        <PaginationItem disabled={!canNextPage}>
                            <PaginationLink href="#next-page" onClick={e => {
                                e.preventDefault();
                                nextPage();
                            }}
                                className="bg-info text-white"
                            >
                                <i className='fa fa-angle-double-right'></i>
                            </PaginationLink>
                        </PaginationItem>
                    </Pagination>
                </nav>
            </Row>
        </>
    );
};

export default StudiesList;
