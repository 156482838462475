import moment from "moment";
import React, { useEffect, useMemo, useState, useRef } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { useSortBy, useTable } from "react-table";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Table,
    Modal,
    ModalBody,
    ModalHeader,
} from "reactstrap";
import { useTranslate } from "react-translate";
import LoadingLocal from 'plugins/TS-lib-utils-public/components/Loading/LoadingLocal';
import nativeSender from 'helpers/nativeSender';
import classnames from 'classnames';
import Icon from "components/Icons/Icon";

import DoctorReview from "components/Laboratories/DoctorReview";
import DoctorsService from "services/DoctorsService";
import { actionGetStudy } from "store/actions/laboratoryActions";
import { actionInvitePatient } from 'store/actions/patientsActions';

const LaboratoryDetail = () => {
    const dispatch = useDispatch();
    const { reportId, patientId } = useParams("reportId");
    const { study, loadingAiReview, loadingStudy } = useSelector(state => state.laboratoryReducer);
    const history = useHistory();
    const t = useTranslate('laboratory-detail');
    const thl7 = useTranslate('hl7-status');
    const { nativeWebView } = useSelector(state => state.commonReducer);
    const { practice } = useSelector(state => state.practicesReducer);
    const [review, setReview] = useState('');
    const user = useSelector(state => state.userReducer);
    const { doctorInfo } = useSelector(state=>state.userDataReducer);
    const [aiFlag, setAiFlag] = useState(false);
    const [oldModal, setOldModal] = useState(false);
    const [readAiComment, setReadAiComment] = useState(false);
    const [systemTemplates, setSystemTemplates] = useState([]);

    const [activeTab, setActiveTab] = useState('1');

    const toggle = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const fetchTemplates = async () => {
        const { data } = await DoctorsService.getSystemTemplates();
        setSystemTemplates(data);
    }

    useEffect(() => {
        fetchTemplates();
    }, []);
    
    useEffect(() => {
        dispatch(actionGetStudy(reportId, practice._id, doctorInfo.ai_feature_enabled, patientId));
    }, [reportId, doctorInfo]);

    const invitePatient = (patientId) => {
        dispatch(actionInvitePatient(patientId, doctorInfo.doctor_pifs_id, reportId));
    }

    useEffect(() => {
        if (!loadingStudy && study?.is_old && !oldModal) {
            setOldModal(true);
        }
    }, [loadingAiReview, study]);

    let data = useMemo(
        //|| ('Set ID - NTE' in el)
        () => Object.keys(study).length && study.medicalValues ? study.medicalValues.map(p => {
            const tableData = {
                c1: p?.['Observation Identifier'].replace(/^([\S]{1,})\s/, ""),
                c2: p?.['Observation Value'],
                c3: p?.['Units'],
                c4: p?.['References Range'],
                c5: p?.['Abnormal Flags'],
                c6: p?.['Comment'].map((commentLine, index) => {
                    return <><p key={index}>{commentLine}</p></>
                }),
            };

            return { ...tableData };

            //}

        }) : [],
        //eslint-disable-next-line
        [study]
    );

    const columns = useMemo(
        () => [
            {
                Header: `${t('observation-identifier')}`,
                accessor: "c1",
                defaultCanSort: true
            },
            {
                Header: `${t('observation-value')}`,
                accessor: "c2",
                disableSortBy: true,
            },
            {
                Header: `${t('units')}`,
                accessor: "c3",
                disableSortBy: true,
            },
            {
                Header: `${t('references-range')}`,
                accessor: "c4",
                disableSortBy: true,
            },
            {
                Header: `${t('abnormal-flags')}`,
                accessor: "c5",
                disableSortBy: true,
            },
            {
                Header: `${t('comment')}`,
                accessor: "c6",
                disableSortBy: true,
            },
        ],
        //eslint-disable-next-line
        [study]
    );


    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({ columns, data, initialState: { hiddenColumns: ["c5"] } }, useSortBy);

    function base64ToArrayBuffer(base64) {
        var binaryString = window.atob(base64);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    }
    // Function will execute on click of button
    const convertPDFToUrl = () => {
        // using Java Script method to get PDF file
        var bytes = base64ToArrayBuffer(study.reportFile);
        var blob = new Blob([bytes], { type: "application/pdf" });
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        return fileURL;
    }

    return (
        // <div className="content dashboard">
        <Card>
            <CardBody>
                <Card>

                    <CardHeader>
                        <CardTitle className="d-flex align-content-center">
                            <div className="general-card-title-img text-primary mr-2">
                            <i className="icon-microscope" />
                            </div>
                            <div className="general-card-title text-primary">
                                {t('laboratory-details')}
                            </div>
                            <div style={{marginTop: "10px", marginLeft: "10px", fontSize: "20px"}} className="general-card-title text-primary">
                                {t('ai-comment-info')}
                            </div>
                        </CardTitle>
                    </CardHeader>





                    {loadingStudy ? (<LoadingLocal />)
                        : (

                            <CardBody>

                                <Button
                                    size="md"
                                    color="info"
                                    onClick={
                                        (e) => {
                                            e.preventDefault();
                                            history.push(`/admin/lab-studies`);
                                        }
                                    }>
                                    {t('go-back')}
                                </Button>

                                {Object.keys(study).length && !study.patient.isTelepraxen ? (
                                        <Button 
                                        color={`primary ${doctorInfo.ai_feature_enabled ? "ml-2" : ""}`} 
                                        onClick={() => {invitePatient(study.patient.pifs_id)}}
                                        >
                                            {t('patient-not-registered')}
                                            <i className="icon icon-send ml-2"></i>
                                        </Button>
                                    ) : (<></>)}

                                <div >
                                    {
                                        study.metadata && study.patient && (
                                            <table border="0" className="metadata-table">
                                                <thead>
                                                    <tr>
                                                        <th colSpan={2}>
                                                            <b>{t("patient")}: </b> {study.patient.firstName} {study.patient.lastName} *{moment(study.patient.birthday, "YYYYMMDD").format(user.dateFormat)}
                                                        </th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    <tr>    
                                                        <td><b>{t("order")}: </b> {study.metadata.adminValues.order_number}</td>
                                                        <td><b>{t("control")}: </b> {study.metadata.adminValues.control_number}</td>
                                                    </tr>
                                                    
                                                    <tr>
                                                        <td><b>{t("report-date")}: </b> {moment(study.metadata.updated_at, "YYYYMMDDhhmmss").format(user.dateFormat)}</td>
                                                        <td><b>{t("reception-date")}: </b> {moment(study.metadata.created_at, "YYYYMMDDhhmmss").format(user.dateFormat)}</td>
                                                    </tr>

                                                    <tr>
                                                        <td><b>{t("status")}: </b> {thl7(study.metadata.reportStatus)}</td>
                                                        <td><b>{t("study-type")}: </b>{study.metadata.adminValues.study_type ? t(study.metadata.adminValues.study_type.toLowerCase()) : t("unknown")}</td>
                                                    </tr>
                                                </tbody>
                                                
                                            </table>
                                        )
                                    }
                                    {/* <textarea name="" id="" cols="60" rows="10"></textarea> */}

                                    {study.ai_review_error && (
                                        <div className="ai-loading-error">
                                            Die KI-Engine ist nicht verfügbar. <br/> Bitte versuchen Sie in ein paar Minuten, das Studio zu betreten.
                                        </div>
                                    )}
                                </div>

                                <div className="d-flex justify-content-between">
                                    <div className="d-flex flex-column">
                                        {doctorInfo.ai_feature_enabled ? (
                                            <>
                                                {Object.keys(study).length && study.patient.isTelepraxen ? (
                                                    <>
                                                        <span className="badge badge-success mt-3">
                                                            {t('patient-registered')}
                                                        </span>
                                                        <span className="badge badge-save-info">
                                                            {t('info-badge-save')}
                                                        </span>
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                            </>
                                        ) : (<></>)}


                                        {oldModal && (
                                            <span className="badge badge-study-old">
                                                {t('study-older')}
                                            </span>
                                        )}
                                        
                                    </div>

                                    {loadingAiReview ? (
                                        <div className="ai-loading">
                                            <LoadingLocal/>
                                            Loading AI review...
                                        </div>
                                    ) : (<></>)}
                                </div>

                                

                                {Object.keys(study).length && study.patient.isTelepraxen ? (
                                    <Nav pills className="nav-pills-primary mb-4 mt-4">
                                        <NavItem className="profile-item">
                                            <NavLink
                                                className={classnames({ active: activeTab === '1' })}
                                                onClick={() => {
                                                    toggle('1');
                                                }}>
                                                    {t('tab-detail')}
                                            </NavLink>
                                        </NavItem>

                                        
                                        <NavItem className="profile-item">
                                            <NavLink
                                                className={classnames({ active: activeTab === '2' })}
                                                onClick={() => {
                                                    toggle('2');
                                                }}>
                                                    {t('tab-doctor-review')}
                                            </NavLink>
                                        </NavItem>
                                        </Nav>
                                ) : (<></>)}

                                <TabContent activeTab={activeTab}>
                                    <TabPane tabId="1">
                                        {activeTab === '1' && (
                                            <>
                                                {
                                                    
                                                    study.reportFile && (
                                                        <Button
                                                            size="md"
                                                            color="info"
                                                            href={!nativeWebView ? convertPDFToUrl() : null}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            onClick={(e) => {
                                                                if (nativeWebView) {
                                                                    e.preventDefault();
                                                                    return nativeSender.send('DOWNLOAD_REPORT', { reportId: reportId, doctorId: study.doctor_id, patientId: study.patient_id });
                                                                }
                                                            }}
                                                        //descomentar la siguiente linea si lo que se desea es que se descargue directamente
                                                        //download={reportId + ".pdf"}
                                                        >
                                                            {t('show-pdf')}
                                                        </Button>
                                                    )
                                                    
                                                }
                                                <div className="table-responsive">
                                                    <Table {...getTableProps()}>
                                                        <thead>
                                                            {headerGroups.map(headerGroup => (
                                                                <tr {...headerGroup.getHeaderGroupProps()}>
                                                                    {headerGroup.headers.map(column => (
                                                                        <th
                                                                            className={`session-table-header`}
                                                                            {...column.getHeaderProps(column.getSortByToggleProps())}
                                                                        >
                                                                            {column.render('Header')}
                                                                        </th>
                                                                    ))}
                                                                </tr>
                                                            ))}
                                                        </thead>
                                                        <tbody {...getTableBodyProps()}>
                                                            {rows.map((row, i) => {
                                                                prepareRow(row)
                                                                return (
                                                                    <tr {...row.getRowProps((state, rowInfo) => {
                                                                        if (rowInfo && rowInfo.row) {
                                                                            return {
                                                                                style: {
                                                                                    backgroundColor: rowInfo.row.values.c5 !== '' ? '#ffdddd' : '',
                                                                                },
                                                                                key: `tr-${i}`
                                                                            }
                                                                        } else return { key: `tr-${i}` }
                                                                    })}
                                                                        
                                                                    >
                                                                        {row.cells.map((cell, j) => {
                                                                            return (
                                                                                <td
                                                                                    {...cell.getCellProps({key: `td-${j}-row-${i}`})}
                                                                                >
                                                                                    {cell.render('Cell')}
                                                                                </td>
                                                                            )
                                                                        })}
                                                                    </tr>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </>
                                        )}
                                    </TabPane>

                                    {study.patient && study.patient.isTelepraxen && (
                                        <TabPane tabId="2">
                                            {activeTab === '2' && (
                                                <DoctorReview 
                                                study={study} 
                                                reportId={reportId} 
                                                review={review} 
                                                setReview={setReview}
                                                aiFlag={aiFlag} 
                                                setAiFlag={setAiFlag}
                                                readAiComment={readAiComment}
                                                setReadAiComment={setReadAiComment}
                                                systemTemplates={systemTemplates}
                                                />
                                            )}
                                        </TabPane>
                                    )}
                                </TabContent>

                            </CardBody>
                        )}
                </Card>
            </CardBody>
        </Card >
        // </div >
    );
};

export default LaboratoryDetail;