import React, { useState } from "react";
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
} from "reactstrap";
import StudiesList from 'components/Laboratories/StudiesList';
import HistoryList from "components/Laboratories/HistoryList";
import classnames from 'classnames';
import { useTranslate } from "react-translate";
const LaboratoryStudies = () => {

    const [activeTab, setActiveTab] = useState('1');

    const toggle = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };
    const t = useTranslate('laboratory-studies');
    return (
        // <div className="content dashboard">
        <Card>
            <CardBody>
                <Card>
                    <CardHeader>
                        <CardTitle className="d-flex align-content-center">
                            <div className="general-card-title-img text-primary mr-2">
                            <i className="icon-microscope" />
                            </div>
                            <div className="general-card-title text-primary">
                            {t('laboratory-studies')}
                            </div>
                        </CardTitle>
                    </CardHeader>

                    <CardBody>
                        <Nav pills className="nav-pills-primary mb-4">
                            <NavItem className="profile-item">
                                <NavLink
                                    className={classnames({ active: activeTab === '1' })}
                                    onClick={() => {
                                        toggle('1');
                                    }}>
                                    {t('laboratory-studies')}
                                </NavLink>
                            </NavItem>
                            {/* descomentar el siguiente codigo para volver a mostrar el boton history */}
                            {/* <NavItem className="profile-item">
                                    <NavLink
                                        className={classnames({ active: activeTab === '2' })}
                                        onClick={() => {
                                            toggle('2');
                                        }}>
                                        History
                                    </NavLink>
                                </NavItem> */}
                        </Nav>
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId="1">
                                {activeTab === '1' && (
                                    <StudiesList />
                                )}
                            </TabPane>
                            <TabPane tabId="2">
                                {activeTab === '2' && (
                                    <HistoryList />
                                )}
                            </TabPane>
                        </TabContent>

                    </CardBody>
                </Card>
            </CardBody>
        </Card>
        // </div>
    );
};

export default LaboratoryStudies;