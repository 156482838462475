import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Row,
  Col,
  Button,
} from 'reactstrap';
import NotificationsAvatar from './NotificationsAvatar';
import { actionGetNotifications } from 'store/actions/notificationsActions';
import { actionReadNotification } from 'store/actions/notificationsActions';
import { actionReadAllNotifications } from 'store/actions/notificationsActions';
import { actionGetMessageNotification } from 'store/actions/messagesActions';
import NotificationIcon from './NotificationIcon';
import { actionSetBox } from 'store/actions/messagesActions';
import { useHistory } from 'react-router';
import { actionUpdateChatInList, actionGetChatFromUrlParams } from 'store/actions/chatsActions';
import { useTranslate } from 'react-translate';

const Notifications = (props) => {
  const { type, title, icon } = props;
  const isMounted = useRef(true);
  const history = useHistory();
  const dispatch = useDispatch();
  const t = useTranslate('notifications');

  const notificationsState = useSelector(
    (state) => state.notificationsReducer[type]
  );

  const chat = useSelector((state) => state.chatsReducer.chat);
  const practice = useSelector((state) => state.userAuthorizationReducer);

  let notifications = notificationsState.notifications.filter(
    (item) => item.notification.module === type
  );

  const [dropdownOpenMenu, setdropdownOpenMenu] = useState(false);

  const dropdownToggleMenu = useCallback(() => {
    setdropdownOpenMenu(!dropdownOpenMenu);
  }, [dropdownOpenMenu]);

  /**
   * Redirect to inbox or chats
   * @param {string} id message id
   */
  const handleClick = (notification) => {
    if (!notification.readed) {
      dispatch(actionReadNotification(notification._id, 1));
    }
    if (type === 'messages') {
      dispatch(
        actionGetMessageNotification(
          notification.practice,
          notification.data.message_id
        )
      );
      history.push(`/admin/inbox/${notification.data.message_id}`);
      dispatch(actionSetBox('inbox'));
    }
    if (type === 'chats') {
      const id = notification?.data?.chat?._id;
      if (practice?._id !== notification.practice) {
        dispatch(actionGetChatFromUrlParams(id, notification.practice));
      } else {
        if (id && id !== chat?._id) {
          dispatch(actionUpdateChatInList({ _id: id }, false, true));
        }
        history.replace(`/admin/chat/${id}`);
      }
    }
    if (type === 'laboratory') {
      history.push(`/admin/lab-studies-detail/${notification.data.reportId}/${notification.data.patientId}`);
    }
  };

  const handleReadButton = (module) => {
    dispatch(actionReadAllNotifications(module));
  }

  useEffect(() => {
    if (isMounted.current) {
      dispatch(actionGetNotifications(type));
    }
    return () => {
      isMounted.current = false;
    };
  }, [dispatch, type]);

  return (
    <>
      {/*  {mobile ? (
        <span className="adminbar-messages">
          <NavLink
            href="#"
            className="new-item new-item-mobile"
            onClick={() => handleClickLink()}
          >
            <NotificationIcon notifications={notifications} module={type} />
          </NavLink>
        </span>
      ) : ( */}
      <Dropdown
        nav
        tag="div"
        isOpen={dropdownOpenMenu}
        toggle={dropdownToggleMenu}
        className="adminbar-messages"
        onClick={() => {
          setdropdownOpenMenu(!dropdownOpenMenu);
        }}
      >
        <DropdownToggle caret nav className="new-item">
          <NotificationIcon unreaded={notificationsState.unreaded} module={type} />
        </DropdownToggle>
        {dropdownOpenMenu && (
          <DropdownMenu right>
            <div className="text-danger h5 ml-2 d-none d-lg-block">
              <span className={icon}></span> {title}
            </div>

            {notifications.length > 0 ? (
              <>
              <div className='d-flex justify-content-center'>
                <Button
                  color="info"
                  size="sm"
                  onClick={() => handleReadButton(type)}
                >
                  {t("btn-read-all")}
                </Button>
              </div>

              {notifications.map((item, key) => {
                const { notification } = item;
                const date =
                  moment().format('D Y') ===
                    moment(notification.datetime).format('D Y')
                    ? moment(notification.datetime).format('H:mm') + 'Hrs'
                    : moment(notification.datetime).format('D MMM');
                return (
                  <DropdownItem
                    tag="div"
                    className="active"
                    key={key}
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleClick(notification)}
                  >
                    <Row>
                      <Col xs={4} lg={3}>
                        {notification.data.sender ? <NotificationsAvatar user={notification.data.sender} /> : <></>}
                      </Col>

                      <Col xs={8} lg={9} className="message-text">
                        <div className="d-flex">
                          <div
                            className="messages-practice"
                            style={{
                              fontWeight: notification.readed
                                ? 'lighter'
                                : 'bold',
                            }}
                          >
                            {notification.practiceName}
                          </div>
                          <div className="messages-date ml-auto">{date}</div>
                        </div>

                        <div
                          className="messages-user-name mr-1"
                          style={{
                            fontWeight: notification.readed ? 'lighter' : 'bold',
                          }}
                        >
                          {notification.emitterName}
                        </div>

                        <div
                          className="message-subject mr-1"
                          style={{
                            fontWeight: notification.readed ? 'lighter' : 'bold',
                          }}
                        >
                          {notification.text}
                        </div>
                        <div className="message-content"></div>
                      </Col>
                    </Row>
                  </DropdownItem>
                );
              })}
            </>
            ) : (
                <p className="message-content ml-3">
                  {t("no-notifications")}
                </p>
            )}
          </DropdownMenu>
        )}
      </Dropdown>
      {/* )} */}
    </>
  );
};

Notifications.defaultProps = {
  notifications: [],
};

Notifications.propTypes = {
  notifications: PropTypes.array,
  title: PropTypes.string,
  icon: PropTypes.string,
};

export default Notifications;
