const CONSTANTS = {
  //App
  RESET_APP: 'RESET_APP',
  //Common
  SWEET_ALERT: 'SWEET_ALERT',
  COLLAPSE: 'COLLAPSE',
  MOBILE_VERSION: 'MOBILE_VERSION',
  DASHBOARD_MOBILE_VERSION: 'DASHBOARD_MOBILE_VERSION',
  NATIVE_WEBVIEW: 'NATIVE_WEBVIEW',
  SET_AVATARS: 'SET_AVATARS',
  PUSH_AVATAR: 'PUSH_AVATAR',
  REMOVE_AVATARS: 'REMOVE_AVATARS',
  //WEBSOCKETS
  USERS_WEBSOCKET_AUTHORIZED: 'USERS_WEBSOCKET_AUTHORIZED',
  USERS_FORCE_LOGOUT: 'USERS_FORCE_LOGOUT',
  USERS_ONLINE: 'USERS_ONLINE',
  USERS_OFFLINE: 'USERS_OFFLINE',
  WS_NOTIFICATION: 'WS_NOTIFICATION',
  WS_ALERT: 'WS_ALERT',
  WS_STATUS: 'WS_STATUS',
  WS_SET_DATA: 'WS_SET_DATA',
  CHATS_SET_ONLINE: 'CHATS_SET_ONLINE',
  //Practices
  PRACTICES_SET_LIST: 'PRACTICES_SET_LIST',
  PRACTICES_GET_LIST: 'PRACTICES_GET_LIST',
  PRACTITIONERS_SET_LIST: 'PRACTITIONERS_SET_LIST',
  PRACTITIONERS_GET_LIST: 'PRACTITIONERS_GET_LIST',
  PRACTITIONER_DELETE: 'PRACTITIONER_DELETE',
  PRACTITIONER_EDIT: 'PRACTITIONER_EDIT',
  PRACTICES_SET: 'PRACTICE_SET',
  PRACTICES_GET: 'PRACTICE_GET',
  PRACTICES_SAVE: 'PRACTICE_SAVE',
  PRACTICES_DELETE: 'PRACTICE_DELETE',
  PRACTICE_METRIC_SELECTED: 'PRACTICE_METRIC_SELECTED',
  PRACTICE_USERS_SET_DISAFFECTED: 'PRACTICE_USERS_SET_DISAFFECTED',
  //Practitioners
  //Invitations
  INVITATION_SAVE: 'INVITATION_SAVE',
  //Invitations Incoming
  INVITATION_GET: 'INVITATION_GET',
  INVITATION_SET: 'INVITATION_SET',
  INVITATION_GET_LIST: 'INVITATION_GET_LIST',
  INVITATION_SET_LIST: 'INVITATION_SET_LIST',
  INVITATION_RESOLVE: 'INVITATION_RESOLVE',
  //Patients
  PATIENTS_GET_LIST: 'PATIENTS_GET_LIST',
  PATIENTS_SET_LIST: 'PATIENTS_SET_LIST',
  PATIENTS_GET: 'PATIENTS_GET',
  PATIENTS_SET: 'PATIENTS_SET',
  PATIENTS_SAVE: 'PATIENTS_SAVE',
  PATIENTS_EDIT: 'PATIENTS_EDIT',
  PATIENTS_DELETE: 'PATIENTS_DELETE',
  PATIENTS_EDIT_CHECK: 'PATIENTS_EDIT_CHECK',
  PATIENTS_MANUAL_INVITE: 'PATIENTS_MANUAL_INVITE',
  //Messages
  MESSAGES_GET_INBOX: 'MESSAGES_GET_INBOX',
  MESSAGES_SET_INBOX: 'MESSAGES_SET_INBOX',
  MESSAGES_SET_INBOX_SCROLL: 'MESSAGES_SET_INBOX_SCROLL',
  MESSAGES_GET_MSG: 'MESSAGES_GET_MSG',
  MESSAGES_GET_MSG_NOTIFICATION: 'MESSAGES_GET_MSG_NOTIFICATION',
  MESSAGES_GET_FROM_URL: "MESSAGES_GET_FROM_URL",

  MESSAGES_GET_OUTBOX: 'MESSAGES_GET_OUTBOX',
  MESSAGES_SET_OUTBOX: 'MESSAGES_SET_OUTBOX',
  MESSAGES_GET_OUTBOX_MSG: 'MESSAGES_GET_OUTBOX_MSG',
  MESSAGES_SET_OUTBOX_SCROLL: 'MESSAGES_SET_OUTBOX_SCROLL',

  MESSAGES_GET_PRIORITY: 'MESSAGES_GET_PRIORITY',
  MESSAGES_SET_PRIORITY: 'MESSAGES_SET_PRIORITY',
  MESSAGES_SET_PRIORITY_BOX: 'MESSAGES_SET_PRIORITY_BOX',
  MESSAGES_DELETE_PRIORITY: 'MESSAGES_DELETE_PRIORITY',
  MESSAGES_SET_PRIORITYBOX_SCROLL: 'MESSAGES_SET_PRIORITYBOX_SCROLL',

  MESSAGES_GET_SCROLL: 'MESSAGES_GET_SCROLL',
  MESSAGES_LOADING_INBOX: 'MESSAGES_LOADING_INBOX',
  MESSAGES_SET_MSG: 'MESSAGES_SET_MSG',
  MESSAGES_DEL_MSG: 'MESSAGES_DEL_MSG',
  MESSAGES_SEND_MSG: 'MESSAGES_SEND_MSG',
  MESSAGES_LOADING_MSG: 'MESSAGES_LOADING_MSG',
  MESSAGES_SET_BROWSER: 'MESSAGES_SET_BROWSER',
  MESSAGES_GET_SELECTED: 'MESSAGES_GET_SELECTED',
  MESSAGES_SET_SELECTED: 'MESSAGES_SET_SELECTED',

  MESSAGES_SET_BOX: 'MESSAGES_SET_BOX',
  MESSAGES_REFRESH_BOXES: 'MESSAGES_REFRESH_BOXES',

  //Appointments
  APPOINTMENTS_GET: 'APPOINTMENTS_GET',
  APPOINTMENTS_SET: 'APPOINTMENTS_SET',
  APPOINTMENTS_SET_AP: 'APPOINTMENTS_SET_AP',
  APPOINTMENTS_GET_AP: 'APPOINTMENTS_GET_AP',
  //Users
  USERS_LOGIN: 'USERS_LOGIN',
  USERS_LOGOUT: 'USERS_LOGOUT',
  USERS_LOGOUT_BASE: 'USERS_LOGOUT_BASE',
  USERS_LOGOUT_CLEAR: 'USERS_LOGOUT_CLEAR',
  USERS_CHECK_SESSION: 'USERS_CHECK_SESSION',
  USERS_REGISTER: 'USERS_REGISTER',
  USERS_GET: 'USERS_GET',
  USERS_SAVE: 'USERS_SAVE',
  USERS_SET: 'USERS_SET',
  USERS_EDIT: 'USERS_EDIT',
  USERS_CHANGE_PASSWORD: 'USERS_CHANGE_PASSWORD',
  USERS_RECOVER_PASSWORD: 'USERS_RECOVER_PASSWORD',
  USERS_LOADING: 'USERS_LOADING',
  USERS_AUTHORIZATION_SET: 'USERS_AUTHORIZATION_SET',
  USERS_AUTHORIZATION_RESET: 'USERS_AUTHORIZATION_RESET',
  USERS_REFRESH_PERMISSIONS: 'USERS_REFRESH_PERMISSIONS',
  USERS_REFRESH_DATA: 'USERS_REFRESH_DATA',
  USERS_DELETE_ACCOUNT_REQUEST: "USERS_DELETE_ACCOUNT_REQUEST",
  USERS_DELETE_ACCOUNT_PREPARE: "USERS_DELETE_ACCOUNT_PREPARE",
  USERS_DELETE_ACCOUNT: "USERS_DELETE_ACCOUNT",
  //Users settings
  USERS_SET_LANG: 'USERS_SET_LANG',
  USERS_UPDATE_LANG: 'USERS_UPDATE_LANG',
  USERS_SET_DATE_FORMAT: 'USERS_SET_DATE_FORMAT',
  USERS_UPDATE_DATE_FORMAT: 'USERS_UPDATE_DATE_FORMAT',
  //User data
  USERS_GET_LIST: 'USERS_GET_LIST',
  USERS_SET_LIST: 'USERS_SET_LIST',
  USERS_GET_SESSIONS: 'USERS_GET_SESSIONS',
  USERS_SET_SESSIONS: 'USERS_SET_SESSIONS',
  USERS_DELETE_SESSION: 'USERS_DELETE_SESSION',
  //Auth2fa
  AUTH2FA_CREATE: 'AUTH2FA_CREATE',
  AUTH2FA_SET: 'AUTH2FA_SET',
  AUTH2FA_ACTIVATE: 'AUTH2FA_ACTIVATE',
  AUTH2FA_INACTIVATE: 'AUTH2FA_INACTIVATE',
  AUTH2FA_CHECK: 'AUTH2FA_CHECK',
  //Responses
  RESPONSE: 'RESPONSE',
  RESPONSE_CLEAN: 'RESPONSE_CLEAN',
  //Loading
  LOADING: 'LOADING',

  //Menu
  CATEGORY_MENU_UPDATE: 'CATEGORY_MENU_UPDATE',
  //Notifications
  NOTIFICATIONS_GET: 'NOTIFICATIONS_GET',
  NOTIFICATIONS_MESSAGE_SET: 'NOTIFICATIONS_MESSAGE_SET',
  NOTIFICATIONS_CHAT_SET: 'NOTIFICATIONS_CHAT_SET',
  NOTIFICATIONS_LAB_SET: 'NOTIFICATIONS_LAB_SET',
  NOTIFICATIONS_UPDATE: 'NOTIFICATIONS_UPDATE',
  NOTIFICATIONS_READ: 'NOTIFICATIONS_READ',
  NOTIFICATIONS_SUBSCRIBE: 'NOTIFICATIONS_SUBSCRIBE',
  NOTIFICATIONS_READALL: 'NOTIFICATIONS_READALL',
  NOTIFICATIONS_READBYMESSAGES: 'NOTIFICATIONS_READBYMESSAGES',
  //Chats
  CHATS_GET_BY_URL: 'CHATS_GET_BY_URL',
  CHATS_SAVE: 'CHATS_SAVE',
  CHATS_GET: 'CHATS_GET',
  CHATS_REFRESH_CHATS: 'CHATS_REFRESH_CHATS',
  CHATS_SET: 'CHATS_SET',
  CHATS_GET_CHAT: 'CHATS_GET_CHAT',
  CHATS_GET_CHAT_DISAFFECTED: 'CHATS_GET_CHAT_DISAFFECTED',
  CHATS_SET_CHAT: 'CHATS_SET_CHAT',
  CHATS_ADD_USER: 'CHATS_ADD_USER',
  CHATS_DELETE_USER: 'CHATS_DELETE_USER',
  CHATS_REMOVE_ACTIVE_USER: 'CHATS_REMOVE_ACTIVE_USER',
  CHATS_STADISTICS: 'CHATS_STADISTICS',
  CHATS_REFRESH_WS_STATUS: 'CHATS_REFRESH_WS_STATUS',
  CHATS_SET_RECIPIENTS: 'CHATS_SET_RECIPIENTS',
  CHATS_DELETE: 'CHATS_DELETE',
  CHATS_DELETE_LOCAL: 'CHATS_DELETE_LOCAL',
  CHATS_UPDATE_CHAT_IN_LIST: 'CHATS_UPDATE_CHAT_IN_LIST',
  CHATS_PRACTITIONERS_SYNC: 'CHATS_PRACTITIONERS_SYNC',
  CHATS_SHOW_INBOX: 'CHATS_SHOW_INBOX',
  CHATS_ERROR: 'CHATS_ERROR',
  CHATS_RECIPIENTS_SET_FLAGS: 'CHATS_RECIPIENTS_SET_FLAGS',
  CHATS_FILTER: 'CHATS_FILTER',
  CHATS_SET_LOADING: 'CHATS_SET_LOADING',
  //Chats-Messages
  CHATS_M_REFRESH_CHAT: 'CHATS_M_REFRESH_CHAT',
  CHATS_M_GET: 'CHATS_M_GET',
  CHATS_M_SET: 'CHATS_M_SET',
  CHATS_M_SET_NEW: 'CHATS_M_SET_NEW',
  CHATS_M_DELETE: 'CHATS_M_DELETE',
  CHATS_M_READ: 'CHATS_M_READ',
  CHATS_M_SEND: 'CHATS_M_SEND',
  CHATS_M_PUSH_TEMP: 'CHATS_M_PUSH_TEMP',
  CHATS_M_SCROLL: 'CHATS_M_SCROLL',
  CHATS_M_UPDATE: 'CHATS_M_UPDATE',
  CHATS_M_ADD_EMOJI: 'CHATS_M_ADD_EMOJI',
  CHATS_M_REMOVE_EMOJI: 'CHATS_M_REMOVE_EMOJI',
  // Profile
  PROFILE_SAVE: 'PROFILE_SAVE',
  PROFILE_SET: 'PROFILE_SET',
  // Help
  HELPS_GET: 'HELPS_GET',
  HELPS_SET: 'HELPS_SET',
  //Doctor_info
  DOCTOR_GET_INFO: 'DOCTOR_GET_INFO',
  DOCTOR_SET_INFO: 'DOCTOR_SET_INFO',
  //Laboratories
  LAB_UPDATE_REVIEW: 'LAB_UPDATE_REVIEW',
  LAB_SEND_REVIEW: 'LAB_SEND_REVIEW',
  LAB_GET_STUDIES: 'LAB_GET_STUDIES',
  LAB_SET_STUDIES: 'LAB_SET_STUDIES',
  LAB_GET_STUDY: 'LAB_GET_STUDY',
  LAB_SET_STUDY: 'LAB_SET_STUDY',
  LAB_READ_AI_COMMENT: 'LAB_READ_AI_COMMENT',
  LAB_DELETE_DRAFT: 'LAB_DELETE_DRAFT',
  LAB_LOADING_AI_COMMENT: 'LAB_LOADING_AI_COMMENT',
  LAB_LOADING_STUDY_LIST: 'LAB_LOADING_STUDY_LIST',
  LAB_LOADING_STUDY: 'LAB_LOADING_STUDY',
  LAB_SHOW_SENT_MODAL: 'LAB_SHOW_SENT_MODAL',
  LAB_SAVE_TEMPLATE_LOCAL_DATA: 'LAB_SAVE_TEMPLATE_LOCAL_DATA',
  LAB_SET_SEARCH_QUERY: 'LAB_SET_SEARCH_QUERY'
};

export default CONSTANTS;
